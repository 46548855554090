import React from "react"
import PropTypes from "prop-types"

import { makeStyles } from "@material-ui/core/styles"
import { Grid, Icon, Typography } from "@material-ui/core"

import { LocaleContext } from "../../contexts/LocaleContext"

const useStyles = makeStyles(theme => ({
  summaryBox: {
    fontSize: 14,
  },
  summaryOutline: {
    padding: "2px 10px",
    lineHeight: "30px",
  },
}))

const Facilities = ({ accommodation }) => {
  const classes = useStyles()
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <Grid container spacing={2}>
      <Grid item className={classes.summaryBox} xs={6} sm={6} md={3}>
        <Typography component="div">
          <Icon className="icon_set_2_icon-114" />
          &nbsp;{accommodation.data.pax_max} {i18n.persons}
        </Typography>
      </Grid>

      {accommodation.data.dryer && (
        <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
          <Typography component="div">
            <Icon className="icon_set_1_icon-100" />
            {i18n.dryer}
          </Typography>
        </Grid>
      )}

      {accommodation.data.fridge && (
        <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
          <Typography component="div">
            <Icon className="icon-snow" />
            {i18n.fridge}
          </Typography>
        </Grid>
      )}

      {accommodation.data.tv && (
        <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
          <Typography component="div">
            <Icon className="icon_set_2_icon-116" />
            {i18n.tv}
          </Typography>
        </Grid>
      )}

      {accommodation.data.kitchen && (
        <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
          <Typography component="div">
            <Icon className="icon_set_3_restaurant-4" />
            {i18n.kitchen}
          </Typography>
        </Grid>
      )}

      {accommodation.data.non_smoking && (
        <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
          <Typography component="div">
            <Icon className="icon_set_1_icon-47" />
            {i18n.nonSmoking}
          </Typography>
        </Grid>
      )}

      {accommodation.data.parking && (
        <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
          <Typography component="div">
            <Icon className="icon_set_1_icon-27" />
            {i18n.parking}
          </Typography>
        </Grid>
      )}

      {accommodation.data.wifi && (
        <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
          <Typography component="div">
            <Icon className="icon_set_1_icon-86" />
            {i18n.wifi}
          </Typography>
        </Grid>
      )}

      {accommodation.data.pet_friendly && (
        <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
          <Typography component="div">
            <Icon className="icon_set_1_icon-22" />
            {i18n.petFriendly}
          </Typography>
        </Grid>
      )}

      {accommodation.data.grill_place && (
        <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
          <Typography component="div">
            <Icon className="icon_set_3_restaurant-9" />
            {i18n.grillPlace}
          </Typography>
        </Grid>
      )}

      <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
        <Typography component="div">
          <Icon className="icon_set_2_icon-106" />
          {i18n.depositBox}
        </Typography>
      </Grid>

      {accommodation.data.additional_bed && (
        <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
          <Typography component="div">
            <Icon className="icon_set_2_icon-112" />
            {i18n.additionalBed}
          </Typography>
        </Grid>
      )}

      <Grid item xs={6} sm={6} md={3} className={classes.summaryBox}>
        <Typography component="div">
          <Icon className="icon_set_1_icon-12" />
          {i18n.accessability}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Facilities

Facilities.propTypes = {
  accommodation: PropTypes.object.isRequired,
}
