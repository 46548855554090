import React, { useState } from "react"
import PropTypes from "prop-types"

import { DateRange } from "react-date-range"

import "react-date-range/dist/styles.css" // main css file
import "react-date-range/dist/theme/default.css" // theme css file

const DatesPicker = ({ checkIn, checkOut, onChange }) => {
  const [state, setState] = useState([
    {
      startDate: new Date(checkIn),
      endDate: new Date(checkOut),
      key: "selection",
    },
  ])

  return (
    <DateRange
      showDateDisplay={false}
      showMonthAndYearPickers={false}
      showPreview={true}
      color={"#90111b"}
      rangeColors={["#90111b", "#90111b", "#90111b"]}
      weekStartsOn={1}
      onChange={item => {
        setState([item.selection])
        onChange(item.selection)
      }}
      minDate={new Date()}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      ranges={state}
      direction="horizontal"
    />
  )
}

export default DatesPicker

DatesPicker.propTypes = {
  checkIn: PropTypes.string.isRequired,
  checkOut: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
