import React from "react"
import PropTypes from "prop-types"
import AnchorLink from "react-anchor-link-smooth-scroll"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
  Divider,
  Grid,
  Link as MaterialLink,
  Typography,
} from "@material-ui/core"

import PhoneRoundedIcon from "@material-ui/icons/PhoneRounded"
import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded"

import { LocaleContext } from "../contexts/LocaleContext"
import Breadcrumbs from "../components/Breadcrumbs"
import ImageSlider from "../components/ImageSlider"
import Facilities from "../components/accommodations/Facilities"
import AccommodationMap from "../components/maps/AccommodationMap"
import AccommodationBookCard from "../components/cards/AccommodationBookCard"
import NeedHelpBlock from "../components/NeedHelpBlock"
import SEO from "../components/SEO"

const useStyles = makeStyles(theme => ({
  topWrapper: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 60px)",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 110px)",
    },
  },
  mainImage: {
    height: "100%",
  },
  topOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.6)",
    top: 0,
    left: 0,
  },

  pageTitle: {
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: 700,
    textAlign: "center",
  },

  pageSubtitle: {
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
    textAlign: "center",
  },

  highlight: {
    background: theme.palette.primary.main,
  },

  intro: {
    marginTop: theme.spacing(3),
    lineHeight: 1.2,
    fontWeight: 700,
    letterSpacing: -1,

    "& p": {
      margin: 0,
    },
  },

  bookNowContainer: {
    zIndex: 9999,
    background: theme.palette.common.white,
    position: "sticky",
    top: "calc(100vh - 54px)",
  },

  contents: {
    "& li": {
      fontSize: 20,
      listStyleType: "upper-roman",
    },
  },

  bottomNav: {
    width: "100%",
    zIndex: 999,
    position: "fixed",
    bottom: 0,
    background: theme.palette.primary.main,
  },

  bottomNavWrapper: {
    flexDirection: "row",
  },
  bottomNavLabel: {
    marginLeft: 5,
  },

  bottomNavText: {
    fontSize: 14,
    fontWeight: 700,
    textTransform: "uppercase",
  },

  bottomNavAction: {
    color: theme.palette.common.white,
  },
}))

const Accommodation = ({
  data: { accommodation },
  pageContext: { locale, slug, breadcrumbs, alternateLanguages },
  location,
}) => {
  const classes = useStyles()
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const meta = {
    title: accommodation.data.meta_title,
    description: accommodation.data.meta_description,
    keywords: accommodation.data.meta_keywords,
    author: "Turist in Transilvania",
  }

  const [lastBreadcrumb] = breadcrumbs.slice(-1)
  const { path } = lastBreadcrumb
  const canonical = `${process.env.GATSBY_BASE_URL}${path}`

  const schema = accommodation.data.schema_json.text || ""
  const ogp = accommodation.data.ogp_tags || []

  ogp.push({
    property: "og:image",
    content: accommodation.data.main_image.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: accommodation.data.main_image.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: accommodation.data.main_image.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: accommodation.data.main_image.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const parsedSchema = JSON.parse(schema)

  parsedSchema["@graph"].push({
    "@type": "Hotel",
    "@context": "http://schema.org",
    description: accommodation.data.category.document.data.intro.text,
    image: accommodation.data.category.document.data.image.fluid.src,
    url: canonical,
    name: accommodation.data.category.document.data.title.text,
    priceRange: "$$",
    checkinTime: "14:00:00",
    checkoutTime: "12:00:00",
    petsAllowed: true,
    starRating: {
      "@type": "Rating",
      ratingValue: "3",
    },
  })

  parsedSchema["@graph"].push({
    "@type": ["Product", "Accommodation"],
    "@context": "http://schema.org",
    name: accommodation.data.title.text,
    description: accommodation.data.introduction.text,
    petsAllowed: true,
    image: accommodation.data.main_image.fluid.src,
    aggregateRating: {
      "@type": "AggregateRating",
      bestRating: 5,
      ratingValue: 4.9,
      reviewCount: 223,
    },
    amenityFeature: [
      {
        "@type": "LocationFeatureSpecification",
        name: "Air Conditioning",
        value: "false",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Heating",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Microwave",
        value: "false",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Washing Machine",
        value: "false",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Television",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Parking",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "pets allowed",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Dining Area",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "wheelchair accessible",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Children welcome",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Internet",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "non smoking only",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Wireless Internet",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Coffee Maker",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Hair Dryer",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "building",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "Dishes &amp; Utensils",
        value: "true",
      },
      {
        "@type": "LocationFeatureSpecification",
        name: "First-aid kit",
        value: "true",
      },
    ],
  })

  const getAnchorOffset = () => {
    return 20
  }

  const imageSlides = accommodation.data.slider_images.map(
    ({ slider_image }) => ({
      alt: slider_image.alt,
      fluid: slider_image.fluid,
    })
  )

  return (
    <>
      <SEO
        schema={JSON.stringify(parsedSchema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
        alternateLanguages={alternateLanguages}
      />
      <div className={classes.topWrapper}>
        <Img
          alt={accommodation.data.main_image.alt}
          className={classes.mainImage}
          fluid={accommodation.data.main_image.fluid}
        />
        <Container maxWidth="sm">
          <Grid
            direction="row"
            justify="center"
            alignItems="center"
            container
            className={classes.topOverlay}
          >
            <Grid item xs={8}>
              <Typography
                component="h1"
                variant="h1"
                className={classes.pageTitle}
              >
                {accommodation.data.title.text}
              </Typography>
              <br />

              <Typography
                component="h2"
                variant="h2"
                className={classes.pageSubtitle}
              >
                <span className={classes.highlight}>
                  {accommodation.data.category.document.data.title.text}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <br />
      <Container maxWidth="lg">
        <Grid
          direction="row-reverse"
          justify="space-between"
          container
          spacing={2}
        >
          <Grid item xs={12} md={4} id="book-now">
            <AccommodationBookCard accommodation={accommodation} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              component="h3"
              variant="h1"
              className={classes.intro}
              dangerouslySetInnerHTML={{
                __html: accommodation.data.introduction.html,
              }}
            />
            <br />
            <ul className={classes.contents}>
              {accommodation.data.is_special_offer && (
                <li>
                  <MaterialLink
                    component={AnchorLink}
                    offset={getAnchorOffset()}
                    href="#offer"
                  >
                    {i18n.offerAbout}
                  </MaterialLink>
                </li>
              )}
              <li>
                <MaterialLink
                  component={AnchorLink}
                  offset={getAnchorOffset()}
                  href="#about"
                >
                  {i18n.accommodationAbout}
                </MaterialLink>
              </li>
              <li>
                <MaterialLink
                  component={AnchorLink}
                  offset={getAnchorOffset()}
                  href="#facilities"
                >
                  {i18n.accommodationFacilities}
                </MaterialLink>
              </li>
              <li>
                <MaterialLink
                  component={AnchorLink}
                  offset={getAnchorOffset()}
                  href="#gallery"
                >
                  {i18n.accommodationGallery}
                </MaterialLink>
              </li>

              <li>
                <MaterialLink
                  component={AnchorLink}
                  offset={getAnchorOffset()}
                  href="#location"
                >
                  {accommodation.data.category.document.data.subtitle.text}
                </MaterialLink>
              </li>
              <li>
                <MaterialLink
                  component={AnchorLink}
                  offset={getAnchorOffset()}
                  href="#getting-there"
                >
                  {i18n.gettingThere}
                </MaterialLink>
              </li>
            </ul>
            <br />
            {accommodation.data.is_special_offer && (
              <>
                <Typography component="h2" variant="h3" id="offer">
                  {i18n.offerAbout}
                </Typography>
                <br />
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: accommodation.data.offer_description.html,
                  }}
                />
              </>
            )}
            <br />
            <Typography component="h4" variant="h4" id="about">
              {i18n.accommodationAbout}
            </Typography>
            <br />
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: accommodation.data.description.html,
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
      <Container maxWidth="lg">
        <Typography component="h4" variant="h4" id="facilities">
          {i18n.accommodationFacilities}
        </Typography>
        <br />
        <Facilities accommodation={accommodation} />
      </Container>
      <br />
      <Divider />
      <br />
      <Container maxWidth="lg">
        <Typography component="h4" variant="h4" id="gallery">
          {i18n.accommodationGallery}
        </Typography>
        <br />
        <ImageSlider slides={imageSlides} />
      </Container>
      <br />
      <Divider />
      <br />
      <Container maxWidth="lg" className={classes.container}>
        <Typography component="h4" variant="h4" id="location">
          {accommodation.data.category.document.data.subtitle.text}
        </Typography>

        <Typography
          variant="body1"
          component="div"
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html:
              accommodation.data.category.document.data.location_description
                .html,
          }}
        />
      </Container>
      <br />
      <Divider />
      <br />
      <Container maxWidth="lg">
        <Typography component="h4" variant="h4" id="getting-there">
          {i18n.accommodationMap}
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html:
              accommodation.data.category.document.data
                .getting_there_description.html,
          }}
        />
        <AccommodationMap
          pins={[
            {
              latitude:
                accommodation.data.category.document.data.coordinates.latitude,

              longitude:
                accommodation.data.category.document.data.coordinates.longitude,
              title: accommodation.data.title.text,
            },
          ]}
        />
        <br />

        <NeedHelpBlock />
      </Container>
      <BottomNavigation showLabels className={classes.bottomNav}>
        <BottomNavigationAction
          component={AnchorLink}
          offset={getAnchorOffset() + 50}
          href="#book-now"
          classes={{
            wrapper: classes.bottomNavWrapper,
            label: classes.bottomNavLabel,
          }}
          className={classes.bottomNavAction}
          label={
            <Typography className={classes.bottomNavText}>
              {i18n.book}
            </Typography>
          }
          icon={<DateRangeRoundedIcon />}
        />
        <BottomNavigationAction
          component="a"
          href={`tel:${i18n.phone}`}
          classes={{
            wrapper: classes.bottomNavWrapper,
            label: classes.bottomNavLabel,
          }}
          className={classes.bottomNavAction}
          label={
            <Typography className={classes.bottomNavText}>
              0749 680 770
            </Typography>
          }
          icon={<PhoneRoundedIcon />}
        />
      </BottomNavigation>
    </>
  )
}

export default Accommodation

export const query = graphql`
  query AccommodationQuery($slug: String!, $locale: String!) {
    accommodation: prismicAccommodation(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      uid
      lang
      data {
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        category {
          document {
            ... on PrismicAccommodationCategory {
              data {
                image {
                  alt
                  fluid(maxWidth: 1980, maxHeight: 1070) {
                    ...GatsbyPrismicImageFluid
                  }
                }
                title {
                  text
                }
                subtitle {
                  text
                }
                intro {
                  text
                }
                location_description {
                  html
                }
                getting_there_description {
                  html
                }
                coordinates {
                  latitude
                  longitude
                }
              }
            }
          }
        }
        meta_title
        meta_description
        meta_keywords
        title {
          text
        }
        introduction {
          html
        }
        description {
          html
        }
        is_special_offer
        offer_description {
          html
        }
        prices {
          amount
          currency
        }
        booking_system_id
        main_image {
          alt
          dimensions {
            height
            width
          }

          fluid(maxWidth: 1980) {
            ...GatsbyPrismicImageFluid
          }
        }
        slider_images {
          slider_image {
            alt
            fluid(maxWidth: 1024) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        deposit_box
        stars
        dryer
        fridge
        tv
        grill_place
        kitchen
        non_smoking
        parking
        pet_friendly
        wifi
        terrace
        additional_bed
        accessibility
        pax_max
      }
    }
  }
`

Accommodation.propTypes = {
  data: PropTypes.shape({
    accommodation: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    alternateLanguages: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
