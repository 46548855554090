import React, { useState } from "react"

import PropTypes from "prop-types"
import add from "date-fns/add"
import format from "date-fns/format"
import startOfDay from "date-fns/startOfDay"
import differenceInDays from "date-fns/differenceInDays"
import isAfter from "date-fns/isAfter"
import isSameDay from "date-fns/isSameDay"
import parseISO from "date-fns/parseISO"
import { ro, de, enGB } from "date-fns/locale"

import axios from "axios"

import { makeStyles } from "@material-ui/core/styles"
import {
  Button,
  Divider,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  TextField,
  Typography,
} from "@material-ui/core"

import { Alert } from "@material-ui/lab"

import { LocaleContext } from "../../contexts/LocaleContext"

import DatesPicker from "../DatesPicker"

import CustomButton from "../buttons/CustomButton"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  wrapper: {
    width: "100%",
  },
  stepper: {
    padding: 0,
  },
  stepLabel: {
    color: theme.palette.common.black,
    fontSize: 20,
  },

  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },

  inputLabel: {
    color: "initial",
  },

  priceSummary: {
    maxWidth: 400,
  },
  infoPrice: {
    fontSize: 14,
    fontWeight: 700,
    textAlign: "right",
  },

  totalLabel: {
    fontWeight: 700,
    fontSize: 20,
  },

  totalPrice: {
    fontWeight: 700,
    fontSize: 20,
  },
}))

const AccommodationBookCard = ({ accommodation }) => {
  const classes = useStyles()
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const [valid, setValid] = useState({
    pax: true,
    checkIn: true,
    checkOut: true,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  })

  const checkIn = add(new Date(), { days: 1 })
  const checkOut = add(new Date(), { days: 2 })

  const [dates, setDates] = useState({
    checkIn: format(checkIn, "yyyy-MM-dd"),
    checkOut: format(checkOut, "yyyy-MM-dd"),
  })

  const [values, setValues] = useState({
    pax: 2,
  })

  const [customer, setCustomer] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  })

  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [calculatedPrice, setCalculatedPrice] = useState(false)

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleCustomerChange = e => {
    const { name, value } = e.target

    if (["firstName", "lastName", "email", "phone"].includes(name)) {
      if (value) {
        valid[name] = true
        setValid({ ...valid })
      } else {
        valid[name] = false
        setValid({ ...valid })
      }
    }

    customer[name] = value
    setCustomer({ ...customer })
  }

  const handleChangePax = e => {
    const { value } = e.target

    values.pax = value

    if (value >= 1) {
      valid.pax = true
    } else {
      valid.pax = false
    }

    setValid({ ...valid })
    setValues({ ...values })
  }

  const handleChangeCheckIn = value => {
    const nights = differenceInDays(
      startOfDay(parseISO(dates.checkIn)),
      startOfDay(parseISO(dates.checkOut))
    )

    if (
      isSameDay(value, startOfDay(new Date())) ||
      isAfter(value, startOfDay(new Date()))
    ) {
      valid.checkIn = true
      dates.checkIn = format(value, "yyyy-MM-dd")
    }

    if (
      isSameDay(value, startOfDay(parseISO(dates.checkOut))) ||
      isAfter(value, startOfDay(parseISO(dates.checkOut)))
    ) {
      dates.checkOut = format(add(value, { days: nights }), "yyyy-MM-dd")
    }

    setValid({ ...valid })
    setDates({ ...dates })
  }

  const handleChangeCheckOut = value => {
    if (isAfter(value, parseISO(dates.checkIn))) {
      valid.checkOut = true
      dates.checkOut = format(value, "yyyy-MM-dd")
    }

    setValid({ ...valid })
    setDates({ ...dates })
  }

  const handleChangeDateRange = ranges => {
    const { startDate, endDate } = ranges
    handleChangeCheckIn(startDate)
    handleChangeCheckOut(endDate)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const calculatePrice = () => {
    setIsLoading(true)

    const axiosOptions = {
      url: `${process.env.GATSBY_TOURIST_BACKEND_API_URL}/accommodation/${accommodation.data.booking_system_id}/price`,
      method: "get",
      headers: { "Content-Type": "application/json" },
      params: {
        pax: values.pax,
        checkIn: dates.checkIn,
        checkOut: dates.checkOut,
      },
    }

    axios(axiosOptions)
      .then(response => {
        setIsLoading(false)
        setCalculatedPrice(response.data)
        handleNext()
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const bookAccommodation = () => {
    setIsLoading(true)

    const axiosOptions = {
      url: `${process.env.GATSBY_TOURIST_BACKEND_API_URL}/accommodation/${accommodation.data.booking_system_id}/book`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: {
        pax: values.pax,
        checkIn: dates.checkIn,
        checkOut: dates.checkOut,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        type: "individual",
      },
    }

    axios(axiosOptions)
      .then(response => {
        setIsLoading(false)
        handleNext()
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  const getSubHeader = () => {
    if (activeStep === 0) {
      return i18n.stepTitle1Accommodation
    }

    if (activeStep === 1) {
      return i18n.stepTitle2Accommodation
    }

    if (activeStep === 2) {
      return i18n.stepTitle3Accommodation
    }

    return ""
  }

  return (
    <Card className={classes.root} variant="outlined" square>
      <CardHeader title={i18n.book} subheader={getSubHeader()} />
      <CardContent>
        <form className={classes.container} noValidate>
          <Grid container spacing={3} direction="row" alignItems="stretch">
            {activeStep === 0 && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="pax"
                      variant="outlined"
                      fullWidth
                      id="pax"
                      label={i18n.persons}
                      value={values.pax}
                      error={!valid.pax}
                      onChange={handleChangePax}
                      type="number"
                      className={classes.textField}
                      inputProps={{
                        min: 1,
                        max: accommodation.data.pax_max,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        color: "primary",
                        className: classes.inputLabel,
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <DatesPicker
                      checkIn={dates.checkIn}
                      checkOut={dates.checkOut}
                      onChange={handleChangeDateRange}
                    />
                  </Grid>

                  <Grid item xs={12}></Grid>
                </Grid>
              </Grid>
            )}

            {calculatedPrice && activeStep === 1 && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <List dense className={classes.priceSummary}>
                      <ListItem>
                        <ListItemText primary={i18n.persons} />
                        <ListItemSecondaryAction className={classes.infoPrice}>
                          {values.pax}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider light />
                      <ListItem>
                        <ListItemText primary={i18n.checkIn} />
                        <ListItemSecondaryAction className={classes.infoPrice}>
                          {`${format(parseISO(dates.checkIn), "PP", {
                            locale:
                              lang.locale === "de-de"
                                ? de
                                : lang.locale === "ro"
                                ? ro
                                : enGB,
                          })} `}
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider light />
                      <ListItem>
                        <ListItemText primary={i18n.checkOut} />
                        <ListItemSecondaryAction className={classes.infoPrice}>
                          {`${format(parseISO(dates.checkOut), "PP", {
                            locale:
                              lang.locale === "de-de"
                                ? de
                                : lang.locale === "ro"
                                ? ro
                                : enGB,
                          })} `}
                        </ListItemSecondaryAction>
                      </ListItem>

                      <Divider light />
                      <Divider light />
                      <Divider light />
                      <ListItem>
                        <ListItemText>
                          <Typography
                            className={classes.totalLabel}
                            component="div"
                          >
                            {i18n.total}
                          </Typography>
                        </ListItemText>
                        <ListItemSecondaryAction className={classes.totalPrice}>
                          {`${calculatedPrice.amount} ${calculatedPrice.currency}`}
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeStep === 2 && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="firstName"
                      variant="outlined"
                      fullWidth
                      id="firstName"
                      label={i18n.firstName}
                      error={!valid.firstName}
                      required
                      value={customer.firstName}
                      onChange={handleCustomerChange}
                      type="text"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                        color: "primary",
                        className: classes.inputLabel,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="lastName"
                      variant="outlined"
                      fullWidth
                      id="lastName"
                      label={i18n.lastName}
                      required
                      value={customer.lastName}
                      error={!valid.lastName}
                      onChange={handleCustomerChange}
                      type="text"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                        color: "primary",
                        className: classes.inputLabel,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      variant="outlined"
                      fullWidth
                      id="email"
                      label={i18n.email}
                      error={!valid.email}
                      required
                      value={customer.email}
                      onChange={handleCustomerChange}
                      type="email"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                        color: "primary",
                        className: classes.inputLabel,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="phone"
                      variant="outlined"
                      fullWidth
                      id="phone"
                      label={i18n.phoneInput}
                      error={!valid.phone}
                      required
                      value={customer.phone}
                      onChange={handleCustomerChange}
                      type="phone"
                      className={classes.textField}
                      InputLabelProps={{
                        shrink: true,
                        color: "primary",
                        className: classes.inputLabel,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {activeStep === 3 && (
              <Grid item xs={12}>
                <Alert icon={false}>
                  <Typography variant="h4">{i18n.bookingThanks}</Typography>
                  <br />
                  <Typography>{i18n.bookingTourThanksDescription}</Typography>
                </Alert>
              </Grid>
            )}
          </Grid>
        </form>
      </CardContent>
      <CardActions>
        {activeStep === 0 && (
          <CustomButton
            fullWidth
            color="primary"
            onClick={() => calculatePrice()}
            disabled={isLoading}
          >
            {i18n.calculatePrice}
          </CustomButton>
        )}

        {activeStep === 1 && (
          <>
            <Button fullWidth onClick={() => handleBack()} disabled={isLoading}>
              {i18n.back}
            </Button>
            <CustomButton
              color="primary"
              fullWidth
              onClick={() => handleNext()}
              disabled={isLoading}
            >
              {i18n.continue}
            </CustomButton>
          </>
        )}

        {activeStep === 2 && (
          <>
            <Button fullWidth onClick={() => handleBack()} disabled={isLoading}>
              {i18n.back}
            </Button>
            <CustomButton
              color="primary"
              fullWidth
              onClick={() => bookAccommodation()}
              disabled={isLoading}
            >
              {i18n.requestBooking}
            </CustomButton>
          </>
        )}
      </CardActions>
    </Card>
  )
}

export default AccommodationBookCard

AccommodationBookCard.propTypes = {
  accommodation: PropTypes.object.isRequired,
}
